export default {
    data() {
        return {
            title: null,
            message: null,
            id: null,
            btnCancel: null,
            btnConfirm: null,
            enable: false,
            item: null
        }
    },
    methods: {
        forceRerenderView(item) {
            this.title = item.title
            this.message = item.message
            this.id = item.id
            this.btnCancel = item.btnCancel
            this.btnConfirm = item.btnConfirm
            this.item = item.item
            this.$nextTick(()=>{
                this.enable = true
            })
        },
        confirm() {
            this.enable = false
            this.$emit('onCompleted', this.id)
        },
        cancel() {
            this.enable = false
        }
    }
}