import userServices from '../../../services/user'
import orgServices from '../../../services/notisphere/organization'
import UserModal from './UserModal'
import ErrorModal from '../../share/ErrorModal'
import DeactivateModal from './DeactivateModal'
import { USER_STATUS, EMAIL_ACTIVITY,EMAIL_STATUS,USERS_STATUS } from '../../../misc/commons'
import store from '../../../store'
export default {
    components: {
        UserModal,
        ErrorModal,
        DeactivateModal
    },
    data() {
        return {
            total: 0,
            delay: 700,
            clicks: 0,
            timer: null,
            items: [],
            searchQuery: {},
            organization_id: null,
            organizations: [],
            user_status: USER_STATUS,
            user_email_status: USERS_STATUS,
            email_status: EMAIL_ACTIVITY,
            status: EMAIL_STATUS,
            userModalKey: 0,
            errorModalKey: 0,
            deactivateModalKey: 0,
            createdEmailTime: null,
            createdEmail: null,
        }
    },
    mounted() {
        if (this._isNotisphere) {
            this.fetchOrgMeta()
        }
    },
    computed: {
        showUserAccountDownloadReportBtn() {
            return this._isNotisphere
        },
        header() {
            var arr = [
                {
                    label: 'Name',
                    name: 'full_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email',
                    name: 'email',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Organization',
                    name: 'organization_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Title',
                    name: 'title',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Phone Number',
                    name: 'phone_number',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-30percent',
                },
                {
                    label: 'Role',
                    name: 'role_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Sites',
                    name: 'userSite',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Organization Type',
                    name: 'organization_type',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Registration Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-stt'
                },
                {
                    label: 'Latest Activity',
                    name: 'last_logged_in',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-sttt'
                },
                {
                    label: 'Email Status',
                    name: 'email_activity',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-sttt'
                },
                {
                    label: 'Last Email',
                    name: 'template_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-sttt'
                },
                {
                    label: 'Email Date',
                    name: 'email_status_date',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-sttt'
                },
                {
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ]

            if (this._isNotisphere) {
                return arr
            } else {
                if(store.state.userType =='SUPPLIER')
                {
                    return arr.filter(x => {
                        if (!['organization_name', 'organization_type','userSite'].includes(x.name)) {
                            return x
                        }
                    })
                }
                else{
                    return arr.filter(x => {
                        if (!['organization_name', 'organization_type'].includes(x.name)) {
                            return x
                        }
                    })
                }
            }
        },
        currentUserId() {
            return this.$store.state.user.id ? this.$store.state.user.id : -1
        }
    },
    methods: {
        onCompleted(type) {
            if (type.type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type.type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.createdEmailTime = type.emailTime
            this.createdEmail=type.emailId
            this.fetchData()
        },
        addMoreClick() {
            this.userModalKey += 1
            this.$nextTick(() => {
                this.$refs.userModal.forceRerenderInsert(this.organization_id)
            })
        },
        doubleClick(event){
            this.clicks++
            if(this.clicks === 1) {
                var self = this
                this.timer = setTimeout(function() {
                    self.clicks = 0
                }, this.delay)
            } else{
                clearTimeout(this.timer)
                this.updateUser(event)
                this.clicks = 0
            }
        },
        updateUser(payload, e = null) {
            this.userModalKey += 1
            this.$nextTick(() => {
                this.$refs.userModal.forceRerenderUpdate(payload.id, payload.organization_id,payload.organization_type,payload.status)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        ViewErrorModal(item) {
            this.errorModalKey += 1
            this.$nextTick(() => {
                this.$refs.errorModal.forceRerenderView(
                    item
                )
            })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            userServices.findUser({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                organizationId: this.organization_id
            }).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                    for(let i = 0 ; i < this.items.length ; i++)
                    {
                        if(this.createdEmailTime != null)
                        {
                            if(this.createdEmail == this.items[i].email)
                            {
                                this.items[i].email_status_date = this.createdEmailTime
                            }
                        }
                        if(this.items[i].phone_number != null || this.items[i].phone_number != undefined)
                        {
                            var x = this.items[i].phone_number.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                            this.items[i].phone_number = (x[1] ? x[1] + '-' : '') + x[2] + (x[3] ? '-' + x[3] : '')
                        }
                    }
                }
            })
        },
        fetchOrgMeta() {
            orgServices.findMeta().then(resp => {
                if (!resp.error) {
                    this.organizations = resp.data.d
                }
            })
        },
        sendResetPasswordEmail(id, e) {
            userServices.sendResetPasswordEmail(id).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.fetchData()
                }
            })
            e.stopPropagation()
        },
        resendVerificationEmail(id, e) {
            userServices.resendVerificationEmail(id).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.fetchData()
                }
            })
            e.stopPropagation()
        },
        updateStatus(item, e) {
            var new_status = null
            switch (item.status) {
            case 'SSO':
                new_status = 'DEACTIVATED'
                break
            case 'VERIFIED':
                new_status = 'DEACTIVATED'
                break
            case 'DEACTIVATED':
                new_status = 'VERIFIED'
                break
            }
            if (new_status) {
                userServices.updateStatus({
                    id: item.id,
                    status: new_status
                }).then(resp => {
                    if (!resp.error) {
                        this.onCompleted('UPDATE')
                    }else{
                        if (resp.data.c == 'err_deactivate_provider_admin') {
                            this.deactivateModalKey += 1
                            this.$nextTick(() => {
                                this.$refs.deactivateModal.forceRerenderView({
                                    title: 'Deactivate User',
                                    message: 'This is the last user on this account, are you sure you want to deactivate the user?',
                                    btnCancel: 'Cancel',
                                    btnConfirm: 'Deactivate',
                                    id: item.id,
                                })
                            })
                        }
                    }
                })
            }
            e.stopPropagation()
        },
        deleteUser(item, e) {
            this._showConfirm('You are about to delete this user, this cannot be undone.').then(confirm => {
                if (confirm) {
                    userServices.deleteUser(item.id).then(resp => {
                        if (!resp.error) {
                            this.onCompleted('UPDATE')
                        }
                    })
                }
            })
            e.stopPropagation()
        },
        userForceDeactivate(id){
            userServices.updateStatus({
                id: id,
                status: 'DEACTIVATED',
                forceDeactivate: true
            }).then(resp => {
                if (!resp.error) {
                    this.onCompleted('UPDATE')
                }
            })
        },
        btnUserAccountReportXLSXClick() {
            userServices.downloadUserAccountXLSXReport().then()
        }
    }
}